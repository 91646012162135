export const environment = {
  production: true,
  baseUrl: "https://pmo-server.dev.local",

  msalModule: {
    clientID: "92eb78dc-a5ce-46f7-9afd-d59a48653799",
    authority: "https://login.microsoftonline.com/arpideas.pl/",
    redirectUri: "https://pmo.dev.local",
    appScope: "api://92eb78dc-a5ce-46f7-9afd-d59a48653799/user_impersonation", // Scope udostnione przez Azure podczas rejestracji aplikacji
  },

  groups: {
    canRead: ["Worker", "Team Engine"],
    canWrite: ["Team Engine", "Team PMO"],
  },
};
